import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { useToast } from '../context/ToastContext';
import api from "../services/api";
import { IAlunoForm } from '../interfaces/IAlunos';
import { useAuth } from '../providers/auth';
import Loading from '../components/Loading';
import { IAlunosPeriodo } from '../interfaces/IAlunosPeriodo';
import { SCCalendar, SCCheckbox, SCDropdown, SCFieldset, SCInputMask, SCInputNumber, SCInputText } from '../components';
import { Divider } from 'primereact/divider';
import AlunoResumo from '../components/AlunoResumo';

interface Props {
  aluno: IAlunoForm;
  alunoPeriodo: IAlunosPeriodo
  mensaliade?: any;
  pagamentos?: any[];
  setShowDialog(param: boolean): void;
  deletar(): void;
  reload(): void;
  showDelete: boolean;
  setShowDelete(param: boolean): void;
  loading: boolean;
  setLoading(param: boolean): void;
}

const RecebimentoMensalidade: React.FC<Props> = ({ aluno, alunoPeriodo, pagamentos, reload, deletar, showDelete, setShowDelete, setLoading, loading }) => {
  // é utilizado para o cadastro da ajuda da tela (todas as telas devem ter essa informação)
  // por padrão vamos utilizar o mesmo nome do componente
  const tarefa = 'AlunoForm';
  const { periodoSelecionado } = useAuth();
  const { control, formState: { errors }, getValues, setValue, reset, watch } = useFormContext();

  const [contas, setContas] = useState<any[]>([]);
  const [formasPag, setFormasPag] = useState<any[]>([]);
  const [fontes, setFontes] = useState<any[]>([]);
  const [codigoEstorno, setCodigoEstorno] = useState<any[]>([]);

  const loadConta = async () => {
    try {
      const { data } = await api.get('/planoContas/list', { params: { Tipo: 'ENTRADA' } });
      setContas(data || []);
    } catch (error) {
      console.error('Erro ao carregar as Contas:', error);
      setContas([]);
    }
  };

  const loadFormasPagamento = async () => {
    try {
      const { data } = await api.get('/formasPagamento/combo');
      setFormasPag(data || []);
    } catch (error) {
      console.error('Erro ao carregar as Formas de Pagamento:', error);
      setFormasPag([]);
    }
  };

  const loadFontes = async () => {
    try {
      const { data } = await api.get('/fontes/fonteAtivas', { params: { EmpresaId: periodoSelecionado?.EmpresaId } });
      setFontes(data || []);
    } catch (error) {
      console.error('Erro ao carregar as Fontes:', error);
      setFontes([]);
    }
  };

  const loadCodigoEstorno = async () => {
    try {
      const { data } = await api.get('/codigosestorno/combo');

      setCodigoEstorno(data || [])
    } catch (error) {
      console.error('Erro ao carregar os Códigos de Estorno:', error);
      setCodigoEstorno([]);
    }
  }

  useEffect(() => {
    loadConta()
    loadFormasPagamento()
    loadFontes()
    loadCodigoEstorno()

    setValue('DataPagamento', new Date());    
  }, []);

  const mensaliade = getValues('Mensalidade');
  console.log(mensaliade);
  return (
    <>
      {loading && <Loading full={true} />}
      <div className='grid'>
        <AlunoResumo aluno={aluno} mostraPlanoPagamento={true} mostraFoto mostraTurma alteraFoto/>
      </div>
      <Divider />
      <div className='grid'>
        <div className="col-4 md:col-1">
          <SCInputText
            control={control}
            errors={errors}
            className='readonly-light'
            style={{ textTransform: 'uppercase' }}
            name="Mensalidade.Parcela"
            label='Parcela'
            disabled
          />
        </div>
        <div className="col-8 md:col-6 ">
          <SCInputText
            control={control}
            errors={errors}
            label='Descrição'
            className='readonly-light'
            style={{ textTransform: 'uppercase' }}
            name="Mensalidade.ParcelaDescricao"
            disabled
          />
        </div>
        <div className="col-4 md:col-2">
          <SCInputText
            className='readonly-light'
            label='Data do Venicmento'
            control={control}
            errors={errors}
            name="Mensalidade.DataVencimentoFormatado"
            disabled
          />
        </div>
      </div>
      <div className='grid'>
        <div className="col-4 md:col-2">
          <SCInputNumber
            className='readonly-light'
            control={control}
            errors={errors}
            name="Mensalidade.ValorMensalidade"
            label='Valor Mensalidade'      
            allowEmpty={false}
            mode="decimal"
            minFractionDigits={2}
            min={0}
            locale='BRL'
            disabled
          />
        </div>
        <div className="col-8 md:col-2">
          <SCInputNumber
            control={control}
            errors={errors}
            className='readonly-light'
            name='ValorAdiantado'            
            label='Valor Adiantado'
            allowEmpty={false}
            mode="decimal"
            minFractionDigits={2}
            min={0}            
            locale='BRL'
            disabled
          />
        </div>
        <div className="col-4 md:col-2">
          <SCInputNumber
              className='readonly'
              label='Valor a Pagar'            
              name="ValorAPagar"
              value={mensaliade?.ValorMensalidade-(mensaliade?.ValorAdiantado||0)}
              allowEmpty={false}
              mode="decimal"
              minFractionDigits={2}
              min={0}            
              locale='BRL'
              disabled
            />
        </div>
      </div>

      <Divider />
      
      <SCFieldset legend='Pagamento' className='col-12 mt-2'>
        <div className='grid align-items-center' style={{ marginTop: -20 }}>
          <div className="col-12 md:col-2">
            <SCCalendar
              label='Data'
              errors={errors}
              control={control}
              name='DataPagamento'
              dateFormat='dd/mm/yy'
              maxDate={new Date()}
              // disabled={deleteOutrosRecebimentos || desabilitaCampos}
              showIcon
            />
          </div>

          <div className="col-12 md:col-5 mt-2 mb-2 md:mt-4">
            <SCCheckbox
              control={control}
              name="PagamentoSemValor"
              label='Pagamento sem valor recebido'
              // disabled={deleteOutrosRecebimentos || desabilitaCampos || desabilita}
            />
          </div>
        </div>

        <div className='grid flex align-items-center' style={{ marginTop: -10 }}>
          <div className="col-12 md:col-2">
            <SCInputNumber
              name='ValorMulta'
              control={control}
              errors={errors}
              label='Multa'
              allowEmpty={false}
              // disabled={deleteOutrosRecebimentos || desabilitaCampos || desabilita}
              min={0}
              max={10000}
              minFractionDigits={2}
              maxFractionDigits={2}
              currency='BRL'
              mode='currency'
              locale='pt-BR'
              // required={validaCampos}
            />
          </div>

          <div className="col-12 md:col-2">
            <SCInputNumber
              name='ValorJuros'
              control={control}
              errors={errors}
              label='Juros'
              allowEmpty={false}
              // disabled={deleteOutrosRecebimentos || desabilitaCampos || desabilita}
              min={0}
              max={10000}
              minFractionDigits={2}
              maxFractionDigits={2}
              currency='BRL'
              mode='currency'
              locale='pt-BR'
              // required={validaCampos}
            />
          </div>

          <div className="col-12 md:col-2">
            <SCInputNumber
              name='ValorPagamento'
              control={control}
              errors={errors}
              label='Valor Pago'
              allowEmpty={false}
              // disabled={deleteOutrosRecebimentos || desabilitaCampos || desabilita}
              min={0}
              max={10000}
              minFractionDigits={2}
              maxFractionDigits={2}
              currency='BRL'
              mode='currency'
              locale='pt-BR'
              // required={!!watchDataPagamento && watchValorPagamento > 0}
            />
          </div>

          <div className="col-12 md:col-6">
            <SCDropdown
              control={control}
              errors={errors}
              name="FormasPagamentoId"
              label='Forma de Pagamento'
              options={formasPag}
              optionLabel='Descricao'
              optionValue='FormasPagamentoId'
              // disabled={deleteOutrosRecebimentos || desabilitaCampos || desabilita}
              // required={validaCampos}
            />
          </div>
        </div>

        <div className='grid flex align-items-center'>

          <div className="col-12 md:col-5">
            <SCDropdown
              control={control}
              errors={errors}
              name="CartoesId"
              label='Cartão'
              // options={cartoesFiltrados}
              optionLabel='Maquina'
              optionValue='CartoesId'
              // disabled={deleteOutrosRecebimentos || desabilitaCampos || desabilita || !isCartao}
              style={{ width: "100%" }}
            />
          </div>

          <div className="col-12 md:col-1">
            <SCInputText
              control={control}
              errors={errors}
              name="TarifaValor"
              // label={watchTarifaTipo == 1 ? 'Tarifa(%)' : watchTarifaTipo == 2 ? 'Tarifa(R$)' : 'Tarifa'}
              disabled
              style={{ color: "#000", fontWeight: 'bold', width: "100%" }}
            />
          </div>
          <div className="col-12 md:col-2">
            <SCInputText
              control={control}
              errors={errors}
              name="NumeroDocumento"
              label='Número do Documento'
              // disabled={deleteOutrosRecebimentos || desabilitaCampos || !watchCartao || !isCartao}
              // required={watchCartao ? true : false}
            />
          </div>

          <div className="col-12 md:col-4">
            <SCDropdown
              control={control}
              errors={errors}
              name="FonteId"
              label='Fonte'
              // options={fontes}
              optionLabel='Codigo'
              optionValue='FonteId'
              // disabled={deleteOutrosRecebimentos || desabilitaCampos || desabilita}
              // required={validaCampos}
            />
          </div>
        </div>
        <div className='grid flex align-items-center'>
          <div className="col-12 md:col-2">
            <SCCalendar
              errors={errors}
              label='Bom Para'
              control={control}
              name='DataBomPara'
              dateFormat='dd/mm/yy'
              // disabled={deleteOutrosRecebimentos || desabilitaCampos || desabilita}
              showIcon
              // required={validaCampos}
            />
          </div>

          <div className="col-12 md:col-2">
            <SCCalendar
              errors={errors}
              label='Disponível Em'
              control={control}
              name='DataDisponivel'
              dateFormat='dd/mm/yy'
              // disabled={deleteOutrosRecebimentos || desabilitaCampos || desabilita}
              showIcon
              // required={validaCampos}
            />
          </div>
        </div>

      </SCFieldset>
    </>
  );
};

export default RecebimentoMensalidade;
