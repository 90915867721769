import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { SCButton, SCCalendar, SCCheckbox, SCDropdown, SCFieldset, SCInputMask, SCInputText, SCInputTextarea } from '../components';
import { useToast } from '../context/ToastContext';
import api from "../services/api";
import util from '../utilities/util';
import { Button } from 'primereact/button';
import { ALERTAS, TIPO_CARTAO, CARTAO_BANDEIRA } from '../utilities/constantes';
import { useAuth } from '../providers/auth';
import ICartao from '../interfaces/ICartao';


interface Props {
    cartao?: ICartao;
    setShowDialog(param: boolean): void;
    deletar(): void;
    reload(): void;
    showDelete: boolean;
    setShowDelete(param: boolean): void;
}

const CartaoInstitucionalForm: React.FC<Props> = ({ setShowDialog, cartao, deletar, showDelete, setShowDelete, reload }) => {
    // é utilizado para o cadastro da ajuda da tela (todas as telas devem ter essa informação)
    // por padrão vamos utilizar o mesmo nome do componente
    const tarefa = 'CartaoInstitucionalForm';

    const toast = useToast();

    let defaultValues: ICartao = {
        CartoesId: undefined,
        Institucional: false,
        NumeroCartao: 0,
        FonteSugeridaId: 0,
        CentroCustoId: 0,
        DiaPadraoVencimento: undefined,
        NomeCartao: '',
    };

    const methods = useForm({ defaultValues })
    const { periodoSelecionado } = useAuth();
    const { handleSubmit, control, formState: { errors }, getValues, reset, watch, setValue } = methods;
    const [codigoCartao, setCodigoCartao] = useState<any>(null);
    const [cartaoTipo, setCartaoTipo] = useState<any>(null);
    const [centroCusto, setCentroCusto] = useState<any>(null);
    const [fontes, setFontes] = useState<any>(null);
    const [fonteDisabled, setFonteDisabled] = useState<boolean>(false);

    const timeZone = 'America/Sao_Paulo';

    const watchTipo = watch('Tipo');
    const watchFonte = watch('FonteSugeridaId');

    const load = async () => {
        try {
            // const resultadoFontes = await api.get('/formasPagamento/list');
            // const filtraCartao = resultadoFontes.data.filter((cartao: any) => cartao.Tipo == '3')
            // if (resultadoFontes.data) {
            //     setCodigoCartao(filtraCartao);
            // } else {
            //     setCodigoCartao([]);
            // }

            const result = await api.get('/centroCusto/list');
            if (result.data)
                setCentroCusto(result.data);
            else
                setCentroCusto([]);

            const resultFontes = await api.get('/fontes/list', {
                params: {
                    EmpresaId: periodoSelecionado?.EmpresaId,
                    Tipo: 2
                }
            });
            if (resultFontes.data)
                setFontes(resultFontes.data);
            else
                setFontes([]);


            const cartaoFiltrado = TIPO_CARTAO.filter(cartao =>
                cartao.value === 'C' || cartao.value === 'D'
            );
            setCartaoTipo(cartaoFiltrado)

        } catch (error) {
            console.error('Erro ao buscar dados:', error);
        }

    }

    useEffect(() => {
        load();
        reset(cartao);
    }, []);

    const cancelar = (e: any) => {
        setShowDelete(false);
        setShowDialog(false);
        reset();
    }

    const onSubmit = async (data: ICartao) => {
        let resp;
        try {
            if (watchTipo == 'D' && !watchFonte) {
                toast.showToast({ severity: 'error', summary: 'Atenção', detail: 'Fonte Sugerida é um campo obrigatório.' });
                return
            }
            data.Institucional = true;
            if (data.CartoesId === undefined) {
                resp = await api.post('/cartoes/createCartaoInstitucional', data);
            } else {
                resp = await api.put('/cartoes/updateCartaoInstitucional', data);
            }

            toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
            reload();
            setShowDialog(false);
        } catch (e: any) {
            console.log(e.response.data);
            toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
        }
    }

    const fonteHandler = (value: any) => {
        if (value == 'C') {
            setFonteDisabled(true);
            setValue('FonteSugeridaId', undefined);
        } else {
            setFonteDisabled(false);
        }
    }


    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)} className='pt-0' style={{ minHeight: 300, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} >
                    <div className='grid'>
                        <div className="col-12 md:col-4">
                            <SCInputText
                                control={control}
                                errors={errors}
                                name="NomeCartao"
                                label='Nome do Cartão'
                                style={{ textTransform: 'uppercase' }}
                                required={!showDelete}
                                disabled={showDelete || cartao?.CartoesId !== undefined}
                            />
                        </div>
                    </div>
                    <div className='grid'>
                        <div className="col-12 md:col-4">
                            <SCDropdown
                                control={control}
                                errors={errors}
                                name="Codigo"
                                label='Cartão'
                                options={CARTAO_BANDEIRA}
                                optionLabel='descricao'
                                optionValue='value'
                                tarefa={tarefa}
                                required={!showDelete}
                                disabled={showDelete || cartao?.CartoesId !== undefined}
                            />
                        </div>
                        <div className="col-12 md:col-4">
                            <SCDropdown
                                control={control}
                                errors={errors}
                                name="Tipo"
                                label='Tipo'
                                options={cartaoTipo}
                                optionLabel='descricao'
                                optionValue='value'
                                tarefa={tarefa}
                                required={!showDelete}
                                disabled={showDelete || cartao?.CartoesId !== undefined}
                                onChange={(e) => fonteHandler(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='grid'>
                        <div className="col-12 md:col-4">
                            <SCInputText
                                control={control}
                                errors={errors}
                                name="NumeroCartao"
                                label='Número'
                                maxLength={20}
                                minLength={10}
                                required={!showDelete}
                            />
                        </div>
                        <div className="col-12 md:col-4">
                            <SCInputText
                                control={control}
                                errors={errors}
                                name="DiaPadraoVencimento"
                                label='Dia Padrão de Vencimento'
                                style={{ textTransform: 'uppercase' }}
                                numberOnly
                                required={!showDelete}
                            />
                        </div>
                    </div>
                    <SCFieldset legend='Fontes' className='pt-3'>
                        <div className='grid'>
                            {/* <div className="col-12 md:col-4">
                                <SCDropdown
                                    control={control}
                                    errors={errors}
                                    name="FonteEntradaId"
                                    label='Fonte Entrada'
                                    options={fontes}
                                    optionValue='FonteId'
                                    optionLabel='Codigo'
                                />
                            </div> */}
                            <div className="col-12 md:col-4">
                                <SCDropdown
                                    control={control}
                                    errors={errors}
                                    name="FonteSugeridaId"
                                    label='Fonte Sugerida'
                                    options={fontes}
                                    optionValue='FonteId'
                                    optionLabel='Codigo'
                                    disabled={fonteDisabled || cartao?.Tipo == 'C'}
                                // required={!showDelete}
                                />
                            </div>
                            <div className="col-12 md:col-4">
                                <SCDropdown
                                    control={control}
                                    errors={errors}
                                    name="CentroCustoId"
                                    label='Centro de Custo'
                                    style={{ textTransform: 'uppercase' }}
                                    options={centroCusto}
                                    optionLabel='Descricao'
                                    optionValue='CentroCustoId'
                                />
                            </div>
                        </div>
                    </SCFieldset>
                    {showDelete ? (
                        <div className="flex flex-1 justify-content-between pt-3 px-2">
                            <Button label="Cancelar Exclusão" icon="pi pi-times" className="p-button w-auto p-button-danger" onClick={cancelar} type='button' style={{ height: 32 }} />
                            <Button label="Confirmar Exclusão" icon="pi pi-check"
                                className={`p-button w-auto p-button-success p-button-outlined ${util.isDesktop() ? '' : ' ml-2'}`}
                                onClick={deletar} type='button' style={{ height: 32 }} />
                        </div>
                    ) : (
                        <div className="flex flex-1 justify-content-between pt-3 px-2">
                            <SCButton label="Cancelar" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} style={{ height: 32 }} />
                            <SCButton label="Gravar" icon="pi pi-check" className="p-button w-auto p-button-success" type='submit' style={{ height: 32 }} />
                        </div>
                    )}

                </form>
            </FormProvider >
        </>
    );
};

export default CartaoInstitucionalForm;
