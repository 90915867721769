import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { SCButton, SCCalendar, SCCheckbox, SCDropdown, SCFieldset, SCInputNumber, SCInputText, SCInputTextarea } from '../components';
import { useToast } from '../context/ToastContext';
import api from "../services/api";
import util from '../utilities/util';
import { Button } from 'primereact/button';
import { TabPanel, TabView } from 'primereact/tabview';
import { useAuth } from '../providers/auth';
import IOutrosRecebimentos from '../interfaces/IOutrosRecebimentos';
import { AutoComplete } from 'primereact/autocomplete';
import { ALERTAS } from '../utilities/constantes';
import { addDays, format } from 'date-fns';
import Loading from '../components/Loading';

interface Props {
  outroRecebimento?: IOutrosRecebimentos;
  setOutrosRecebimentosDialog(param: boolean): void;
  deletar(): void;
  reload(): void;
  deleteOutrosRecebimentos: boolean;
  setDeleteOutrosRecebimentos(param: boolean): void;
}

const OutrosRecebimentosForm: React.FC<Props> = ({ setOutrosRecebimentosDialog, outroRecebimento, reload, deletar, deleteOutrosRecebimentos, setDeleteOutrosRecebimentos }) => {
  // é utilizado para o cadastro da ajuda da tela (todas as telas devem ter essa informação)
  // por padrão vamos utilizar o mesmo nome do componente

  const tarefa = 'OutrosRecebimentosForm';
  const toast = useToast();
  const { periodoSelecionado, usuario } = useAuth();

  let defaultValues: IOutrosRecebimentos = outroRecebimento || {
    OutrosRecebimentosId: undefined,
    EmpresaId: periodoSelecionado?.EmpresaId,
    Ano: periodoSelecionado?.Ano,
    Sequencial: periodoSelecionado?.Sequencial,
    NumeroRecibo: 0,
    Matricula: 0,
    Nome: '',
    Turma: '',
    CodigoPagamento: '',
    DataVencimento: null,
    Valor: 0,
    ValorPagar: 0,
    ValorMulta: 0,
    ValorJuros: 0,
    ValorDesconto: 0,
    DataPagamento: null,
    DataDisponivel: null,
    DataBomPara: null,
    ValorPagamento: 0,
    PlanoContaId: null,
    ConvenioId: 0,
    Referencia: '',
    OperadorLancamento: '',
    OperadorAtualizacao: '',
    NumeroDocumento: '',
    FormasPagamentoId: null,
    FonteId: null,
    FlgMeuCaixa: null,
    Observacao: '',
    ChequeResgate: 0,
    DataInicialVencimento: null,
    DataFinalVencimento: null,
    DataInicialPagamento: null,
    DataFinalPagamento: null,
    NomeFiltro: '',
    FiltroOptions: 0,
    PagamentoSemValor: false,
    CartoesId: null,
    TarifaValor: null,
    TarifaTipo: null,
    CodigosEstornoId: null,
  }

  const methods = useForm({ defaultValues })
  const { handleSubmit, control, formState: { errors }, getValues, reset, watch, setValue, clearErrors, setError } = methods;
  const [alunos, setAlunos] = useState<any>(null);
  const [contas, setContas] = useState<any>(null);
  const [formasPag, setFormasPag] = useState<any>(null);
  const [desabilitaNumeroDocumentoPag, setDesabilitaNumeroDocumentoPag] = useState<boolean>(false);
  const [validaCampos, setValidaCampos] = useState<boolean>(false);
  const [desabilitaCampos, setDesabilitaCampos] = useState<boolean>(false);
  const [desabilita, setDesabilita] = useState<boolean>(false);
  const [dadosAlunos, setDadosAlunos] = useState<any[]>([]);
  const [fontes, setFontes] = useState<any>(null);
  const [isCheque, setIsCheque] = useState<boolean>(false);
  const [isCartao, setIsCartao] = useState<boolean>(false);
  const [outPagId, setOutPagId] = useState<any>(null);
  const [turmaId, setTurmaId] = useState<any>(null);
  const [planoPagamentoId, setPlanoPagamentoId] = useState<any>(null);
  const [cartoesFiltrados, setCartoesFiltrados] = useState<any[]>([]);
  const [tarifaTipo, setTarifaTipo] = useState<any>(null);
  const [maquinaSelecionada, setMaquinaSelecionada] = useState<any>(null);
  const [centroDeCusto, setCentroDeCusto] = useState<any>(null);
  const [contaId, setContaId] = useState<any>(null);
  const [qtdDiasRecebimento, setQtdDiasRecebimento] = useState<any>(null);
  const [dataVencimentoCartao, setDataVencimentoCartao] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [codigoEstorno, setCodigoEstorno] = useState<any>(null);


  // WATCHS
  const watchOutrosRecebimentosId = watch('OutrosRecebimentosId')
  const watchFormaPagamento = watch('FormasPagamentoId');
  const watchDataPagamento = watch('DataPagamento');
  const watchValor = watch('Valor')
  const watchDesconto = watch('ValorDesconto')
  const watchValorPagar = watch('ValorPagar')
  const watchValorMulta = watch('ValorMulta')
  const watchValorJuros = watch('ValorJuros')
  const watchPagamentoSemValor = watch('PagamentoSemValor')
  const watchAluno = watch('Nome')
  const watchCartao = watch('CartoesId')
  const watchValorPagamento = watch('ValorPagamento')
  const watchTarifaValor = watch('TarifaValor')
  const watchTarifaTipo = watch('TarifaTipo')
  let horaGravacao = format(new Date(), 'HH:mm');

  const loadAlunos = async (nome: string) => {
    try {
      const { data } = await api.get('/aluno/pesquisaAlunos', {
        params: {
          EmpresaId: periodoSelecionado?.EmpresaId,
          Ano: periodoSelecionado?.Ano,
          Sequencial: periodoSelecionado?.Sequencial,
          Nome: nome
        }
      });

      const alunoData = data || [];
      setAlunos(alunoData.length > 0 ? alunoData.map((a: any) => `${a.Matricula} - ${a.Nome}`) : []);
      setDadosAlunos(alunoData);
    } catch (error) {
      console.error('Erro ao carregar Alunos:', error);
      setAlunos([]);
      setDadosAlunos([]);
    }
  };

  // Função para ser chamada quando um aluno for selecionado
  const onAlunoSelect = async (nomeCompleto: string) => {
    const matricula = nomeCompleto.includes(' - ') ? nomeCompleto.split(' - ')[0] : null;

    if (matricula) {
      setValue('Matricula', Number(matricula));

      const turma = dadosAlunos?.find((t: any) => t.Matricula == matricula);
      const turmaValue = turma ? `${turma.TurmaCodigo} - ${turma.TurmaDescricao}` : '';
      setValue('Turma', turmaValue);

      try {
        const { data } = await api.get('/alunosPeriodo/buscaPlanoPagAluno', {
          params: {
            EmpresaId: periodoSelecionado?.EmpresaId,
            Ano: periodoSelecionado?.Ano,
            Sequencial: periodoSelecionado?.Sequencial,
            Matricula: matricula,
          },
        });

        setPlanoPagamentoId(data.PlanosPagamentoId);
        setValue('CodigoPagamento', data ? `${data.Codigo} - ${data.Descricao}` : '');
      } catch {
        setValue('CodigoPagamento', '');
      }
    } else {
      // Se o aluno não for encontrado ou não tiver matrícula, usa apenas o nome inserido
      setValue('Nome', nomeCompleto);
      setValue('Matricula', null);  // Limpa o campo matrícula se o aluno não existir
      setValue('Turma', ''); // Limpa o campo de turma
      setPlanoPagamentoId(null);
      setValue('CodigoPagamento', '');
    }
  };

  const loadConta = async () => {
    try {
      const { data } = await api.get('/planoContas/list', { params: { Tipo: 'ENTRADA' } });
      setContas(data || []);
    } catch (error) {
      console.error('Erro ao carregar as Contas:', error);
      setContas([]);
    }
  };

  const loadFormasPagamento = async () => {
    try {
      const { data } = await api.get('/formasPagamento/combo');
      setFormasPag(data || []);
    } catch (error) {
      console.error('Erro ao carregar as Formas de Pagamento:', error);
      setFormasPag([]);
    }
  };

  const loadFontes = async () => {
    try {
      const { data } = await api.get('/fontes/fonteAtivas', { params: { EmpresaId: periodoSelecionado?.EmpresaId } });
      setFontes(data || []);
    } catch (error) {
      console.error('Erro ao carregar as Fontes:', error);
      setFontes([]);
    }
  };

  const loadCodigoEstorno = async () => {
    try {
      const { data } = await api.get('/codigosestorno/combo');

      setCodigoEstorno(data || [])
    } catch (error) {
      console.error('Erro ao carregar os Códigos de Estorno:', error);
      setCodigoEstorno([]);
    }
  }

  const salvaOutrosRecebimentos = async (dadosOutrosRecebimentos: any, data: any) => {
    setLoading(true)
    try {
      let resp;
      let id = null;

      if (dadosOutrosRecebimentos.OutrosRecebimentosId === undefined) {
        resp = await api.post('/outrosRecebimentos', dadosOutrosRecebimentos);
        if (resp.data) {
          id = resp.data.OutrosRecebimentosId;
          setOutPagId(id);
        }
      } else {
        resp = await api.put('/outrosRecebimentos', dadosOutrosRecebimentos);
      }

      if (resp && dadosOutrosRecebimentos.DataPagamento) {
        await salvaDiarioFinanceiro(data, id);
        await salvaEnceramentoCaixa(data);

        if (watchCartao) {
          await salvaContasAPagar(data, id);
        }
      }

      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
      setLoading(false)
      reload();
      setOutrosRecebimentosDialog(false);

    } catch (error: any) {
      setLoading(false)
      console.error("Erro ao salvar outros recebimentos:", error);
      toast.showToast({
        severity: 'error',
        summary: 'Atenção',
        detail: `Falha ao salvar: ${error.message}`
      });
    }
  }

  const salvaDiarioFinanceiro = async (data: any, id: any) => {
    const isMeuCaixa = fontes.find((f: any) => f.FonteId === data.FonteId)?.Codigo

    let dadosDiarioFinanceiro = {
      EmpresaId: periodoSelecionado?.EmpresaId,
      Ano: periodoSelecionado?.Ano,
      Sequencial: periodoSelecionado?.Sequencial,
      LancamentoData: new Date(),
      LancamentoHora: horaGravacao,
      LancamentoOperador: usuario?.Apelido,
      Tarefa: 'OUTPAG',
      Opcao: 'INC',
      DataPagamento: data.DataPagamento,
      DataBomPara: data.DataBomPara ? data.DataBomPara : data.DataPagamento,
      DataDisponivel: data.DataBomPara ? data.DataBomPara : data.DataPagamento,
      Nome: data.Nome.toUpperCase(),
      ValorMulta: data.ValorMulta,
      ValorJuros: data.ValorJuros,
      ValorDesconto: data.ValorDesconto,
      Valor: deleteOutrosRecebimentos ? data.ValorPagamento * -1 : data.ValorPagamento,
      Referencia: data.Referencia,
      NumeroRecibo: data?.OutrosRecebimentosId ? data?.OutrosRecebimentosId : id,
      FonteId: data.FonteId,
      FormasPagamentoId: data.FormasPagamentoId,
      ContaValorId: data.PlanoContaId,
      ChequeNumero: isCheque ? data.NumeroDocumento : null,
      CartaoNumero: isCartao ? data.NumeroDocumento : null,
      FlgMeuCaixa: isMeuCaixa === 'MEU CAIXA' ? 1 : 0,
      TurmaId: turmaId,
      Matricula: data.Matricula,
      PlanosPagamentoId: planoPagamentoId,
    }

    let resp;

    try {
      resp = await api.post('/diariofinanceiro', dadosDiarioFinanceiro)
    } catch (error) {
      console.error('Erro ao salvar no Diário Financeiro:', error);
      toast.showToast({
        severity: 'error',
        summary: 'Atenção',
        detail: 'Erro ao salvar o registro no Diário Financeiro',
      });
    }
    return true;
  }

  const salvaEnceramentoCaixa = async (data: any) => {

    let dadosEncerramentoCaixa = {
      EmpresaId: periodoSelecionado?.EmpresaId,
      CaixaData: new Date(),
      CaixaHora: horaGravacao,
      CaixaOperador: usuario?.Apelido,
      CaixaValor: data.ValorPagamento
    }

    try {
      await api.post('/gravacaixa', dadosEncerramentoCaixa)
    } catch (error) {
      console.error('Erro ao salvar no Encerramento de Caixa:', error);
      toast.showToast({
        severity: 'error',
        summary: 'Atenção',
        detail: 'Erro ao salvar o registro no Encerramento de Caixa',
      });
    }
  }

  const salvaContasAPagar = async (data: any, id: any) => {

    let valorTarifa;
    let outrosRecebId;

    const dataVencimentoCartao = watchDataPagamento && watchCartao ? addDays(watchDataPagamento, qtdDiasRecebimento) : null

    if (watchValorPagamento && watchTarifaValor) {
      if (watchTarifaTipo == 1) {
        valorTarifa = (watchValorPagamento * Number(watchTarifaValor)) / 100
      } else {
        valorTarifa = watchTarifaValor
      }
    }

    if (outroRecebimento?.OutrosRecebimentosId === undefined) {
      outrosRecebId = id
    } else {
      outrosRecebId = outroRecebimento?.OutrosRecebimentosId
    }

    // Pesquisando ou criando o Credor Cartão
    let credorId;

    try {
      const { data: credores } = await api.get('/credor/pesquisaMaquina', {
        params: { Nome: maquinaSelecionada }
      });

      // Verifica se encontrou o credor, caso contrário, cadastra um novo
      if (credores.length > 0) {
        credorId = credores[0].CredorId;
      } else {
        const novoCredor = { Nome: maquinaSelecionada.toUpperCase() };
        const { data: novoCredorCadastrado } = await api.post('/credor/', novoCredor);

        credorId = novoCredorCadastrado?.id ?? null;

        if (!credorId) {
          console.error('Falha ao cadastrar novo credor.');
        }
      }
    } catch (error) {
      console.error('Erro na consulta ou cadastro de credor:', error);
    }

    const consulta = await api.get('/parametro', {
      params: {
        EmpresaId: 0,
        Ano: '0000',
        Sequencial: '0',
        Codigo: 'ENTCARAU',
        Grupo: 'F'
      }
    })

    const consultaDinheiro = await api.get('/formasPagamento/localizaDinheiro')

    let dadosContasAPagar = {
      EmpresaId: periodoSelecionado?.EmpresaId,
      CredorId: credorId,
      CentroCustoId: centroDeCusto,
      Referente: `TAXA DE CARTÃO - ${maquinaSelecionada}`,
      DataVencimento: dataVencimentoCartao,
      Valor: valorTarifa,
      ContaValorId: contaId,
      DataProgramada: dataVencimentoCartao,
      ValorProgramado: valorTarifa,
      OutrosRecebimentosId: outrosRecebId,
      DataPagamento: consulta.data.Valor == '1' ? data.DataPagamento : null,
      ValorPagamento: consulta.data.Valor == '1' ? valorTarifa : null,
      FormasPagamentoId: consultaDinheiro.data.FormasPagamentoId,
      FonteId: consulta.data.Valor == '1' ? data.FonteId : null,
      DataBomPara: consulta.data.Valor == '1' ? data.DataBomPara : null,
    }

    try {
      await api.post('/apagar/tarifaCartao', dadosContasAPagar)

      if (consulta.data.Valor === '1') {

        const isMeuCaixa = fontes.find((f: any) => f.FonteId === data.FonteId)?.Codigo

        let resp;
        let dadosDiarioFinanceiro = {
          EmpresaId: periodoSelecionado?.EmpresaId,
          Ano: periodoSelecionado?.Ano,
          Sequencial: periodoSelecionado?.Sequencial,
          LancamentoData: new Date(),
          LancamentoHora: horaGravacao,
          LancamentoOperador: usuario?.Apelido,
          Tarefa: 'APAGAR',
          Opcao: 'INC',
          DataPagamento: data.DataPagamento,
          DataBomPara: data.DataBomPara ? data.DataBomPara : data.DataPagamento,
          DataDisponivel: data.DataBomPara ? data.DataBomPara : data.DataPagamento,
          Nome: data.Nome.toUpperCase(),
          Valor: valorTarifa,
          Referencia: `TAXA DE CARTÃO - ${maquinaSelecionada}`,
          NumeroRecibo: data?.OutrosRecebimentosId ? data?.OutrosRecebimentosId : id,
          FonteId: data.FonteId,
          FormasPagamentoId: consultaDinheiro.data.FormasPagamentoId,
          ChequeNumero: isCheque ? data.NumeroDocumento : null,
          FlgMeuCaixa: isMeuCaixa === 'MEU CAIXA' ? 1 : 0,
          Matricula: data.Matricula,
          PlanosPagamentoId: planoPagamentoId,
          ContaValorId: data.PlanoContaId,
          CartaoNumero: null
        }

        resp = await api.post('/diariofinanceiro', dadosDiarioFinanceiro)
      }
    } catch (error) {
      console.error('Erro ao salvar o registro no Contas a Pagar:', error);
      toast.showToast({
        severity: 'error',
        summary: 'Atenção',
        detail: 'Erro ao salvar o registro no Contas a Pagar',
      });
    }
  }

  const watchCodigoEstornoId = watch('CodigosEstornoId');

  const deletando = async () => {

    if (outroRecebimento?.DataPagamento) {
      let dataFormat = format(new Date(), 'yyyy-MM-dd')

      try {
        const result = await api.get('/consultaEncerramento', {
          params: {
            EmpresaId: periodoSelecionado?.EmpresaId,
            Data: dataFormat,
            Operador: usuario?.Apelido,
            Acao: 'F'
          }
        })

        if (result.data.length > 0) {
          toast.showToast({
            severity: 'error',
            summary: 'Estorno Cancelado',
            detail: `O Caixa está encerrado para o dia de hoje!`,
          });

          return false;
        }
      } catch (error) {
        toast.showToast({
          severity: 'error',
          summary: 'Atenção',
          detail: 'Erro na consulta',
        });
      }
    }

    setLoading(true)

    const hora = format(new Date(), 'HH:mm');
    let apagarId, houvePagamentoTaxa, taxa, idCredor, consultaCredor;


    if (!watchCodigoEstornoId) {
      setLoading(false)
      setError('CodigosEstornoId', { type: 'manual', message: 'Informe um Código de Estorno' });
      return false;
    }

    try {
      // Busca dados em Outros Recebimentos
      const { data } = await api.get('/apagar/buscaOutrosRecebimentos', {
        params: { OutrosRecebimentosId: outroRecebimento?.OutrosRecebimentosId },
      });

      if (data.length > 0) {
        ({ ApagarId: apagarId, DataPagamento: houvePagamentoTaxa, Valor: taxa, CredorId: idCredor } = data[0]);

        if (idCredor) {
          consultaCredor = await api.get('/credor/pesquisaCredor', {
            params: { CredorId: idCredor },
          });
        }

        const dadosEstornoAPagar = {
          EmpresaId: periodoSelecionado?.EmpresaId,
          Ano: periodoSelecionado?.Ano,
          Sequencial: periodoSelecionado?.Sequencial,
          Matricula: 0,
          LancamentoData: new Date(),
          LancamentoHora: hora,
          LancamentoOperador: usuario?.Apelido,
          Tarefa: 'APAGAR',
          Opcao: 'EST',
          DataPagamento: new Date(data[0]?.DataPagamento),
          DataDisponivel: new Date(data[0]?.DataPagamento),
          DataBomPara: new Date(data[0].DataBomPara),
          Nome: idCredor ? consultaCredor?.data[0].Nome.toUpperCase() : null,
          Valor: data[0]?.ValorPagamento ? data[0]?.ValorPagamento * -1 : null,
          Referencia: data[0]?.Referente,
          NumeroRecibo: data[0]?.ApagarId,
          FonteId: data[0]?.FonteId,
          FormasPagamentoId: data[0]?.FormasPagamentoId,
          ContaValorId: data[0]?.ContaValorId,
          ChequeNumero: data[0]?.NumeroDocumentoPagamento,
          CartaoNumero: data[0]?.NumeroDocumentoPagamento,
          FlgMeuCaixa: data[0]?.FlgMeuCaixa ? 1 : 0,
          CodigosEstornoId: watchCodigoEstornoId,
        };

        const dadosApagar = {
          ApagarId: apagarId,
          Excluido: 1,
          ExcluidoData: new Date(),
          ExcluidoHora: hora,
          ExcluidoOperador: usuario?.Apelido,
          CodigosEstornoId: watchCodigoEstornoId,
        };

        const dadosEncerramentoCaixaAPagar = {
          EmpresaId: periodoSelecionado?.EmpresaId,
          CaixaData: new Date(),
          CaixaHora: hora,
          CaixaOperador: usuario?.Apelido,
          CaixaValor: data[0]?.ValorPagamento ? data[0]?.ValorPagamento : null,
        };

        if (houvePagamentoTaxa) {
          await api.post('/diariofinanceiro', dadosEstornoAPagar);
          await api.post('/gravacaixa', dadosEncerramentoCaixaAPagar);

          // Exclui registro em Apagar, se necessário          
          await api.put('/apagar/excluir', dadosApagar);
        }
      }

      const dadosOutrosRecebimentos = {
        OutrosRecebimentosId: outroRecebimento?.OutrosRecebimentosId,
        Excluido: 1,
        ExcluidoData: new Date(),
        ExcluidoHora: hora,
        ExcluidoOperador: usuario?.Apelido,
      };

      const dadosEstorno = {
        EmpresaId: periodoSelecionado?.EmpresaId,
        Ano: periodoSelecionado?.Ano,
        Sequencial: periodoSelecionado?.Sequencial,
        Matricula: outroRecebimento?.Matricula,
        LancamentoData: new Date(),
        LancamentoHora: hora,
        LancamentoOperador: usuario?.Apelido,
        Tarefa: 'OUTPAG',
        Opcao: 'EST',
        DataPagamento: outroRecebimento?.DataPagamento,
        DataDisponivel: outroRecebimento?.DataBomPara,
        DataBomPara: outroRecebimento?.DataBomPara,
        Nome: outroRecebimento?.Nome,
        Valor: outroRecebimento?.ValorPagamento ? outroRecebimento.ValorPagamento * -1 : null,
        Referencia: outroRecebimento?.Referencia,
        NumeroRecibo: outroRecebimento?.OutrosRecebimentosId,
        FonteId: outroRecebimento?.FonteId,
        FormasPagamentoId: outroRecebimento?.FormasPagamentoId,
        ContaValorId: outroRecebimento?.PlanoContaId,
        ChequeNumero: outroRecebimento?.NumeroDocumento,
        CartaoNumero: outroRecebimento?.NumeroDocumento,
        FlgMeuCaixa: outroRecebimento?.FlgMeuCaixa ? 1 : 0,
        CodigosEstornoId: watchCodigoEstornoId,
      };

      const dadosEncerramentoCaixa = {
        EmpresaId: periodoSelecionado?.EmpresaId,
        CaixaData: new Date(),
        CaixaHora: hora,
        CaixaOperador: usuario?.Apelido,
        CaixaValor: outroRecebimento?.ValorPagamento ? outroRecebimento.ValorPagamento * -1 : null,
      };



      // Exclui Outros Recebimentos
      await api.put('/outrosRecebimentos/excluir', dadosOutrosRecebimentos);

      // Se houve pagamento, registra no diário financeiro e no caixa
      if (outroRecebimento?.DataPagamento) {
        await api.post('/diariofinanceiro', dadosEstorno);

        await api.post('/gravacaixa', dadosEncerramentoCaixa);
      }

      // Realiza a operação de deletar
      deletar();
      setLoading(false)
    } catch (error) {
      console.error('Erro ao deletar registro:', error);
      toast.showToast({
        severity: 'error',
        summary: 'Atenção',
        detail: 'Erro ao deletar o registro',
      });
    }
  };


  const onSubmit = async (data: any) => {
    let hoje = new Date();

    if (watchDataPagamento) {
      let dataFormat = format(new Date(watchDataPagamento), 'yyyy-MM-dd')

      try {
        const result = await api.get('/consultaEncerramento', {
          params: {
            EmpresaId: periodoSelecionado?.EmpresaId,
            Data: dataFormat,
            Operador: usuario?.Apelido,
            Acao: 'F'
          }
        })

        let dataFormatada = format(new Date(watchDataPagamento), 'dd-MM-yyyy')

        console.log(result)

        if (result.data.length > 0) {
          toast.showToast({
            severity: 'error',
            summary: 'Atenção',
            detail: `O Caixa está encerrado para a data: ${dataFormatada}`,
          });

          return false;
        }
      } catch (error) {
        toast.showToast({
          severity: 'error',
          summary: 'Atenção',
          detail: 'Erro na consulta',
        });
      }
    }

    // Validação para garantir que o campo Nome foi preenchido
    if (!data.Nome || data.Nome.trim() === '') {
      toast.showToast({
        severity: 'error',
        summary: 'Atenção',
        detail: 'Campo Nome é obrigatório!',
      });
      return false;
    }

    // Verifica se o nome está no formato 'Matricula - Nome' e ajusta apenas se estiver
    if (data.Nome.includes(' - ')) {
      data.Nome = data.Nome.split(' - ')[1];
    }

    // Verifica se o código de pagamento está no formato 'Código - Descrição' e ajusta apenas se estiver
    if (data.CodigoPagamento && data.CodigoPagamento.includes(' - ')) {
      data.CodigoPagamento = data.CodigoPagamento.split(' - ')[1];
    }

    // Define o operador com base no OutrosRecebimentosId
    const operadorKey = data.OutrosRecebimentosId === undefined ? 'OperadorLancamento' : 'OperadorAtualizacao';
    data[operadorKey] = usuario?.Apelido;

    // Validação do valor
    if (data.Valor == 0) {
      setError('Valor', { type: 'manual', message: 'Informe um valor válido!' });
      return false;
    }

    // Validação de pagamento
    if (data.DataPagamento && !data.PagamentoSemValor && data.ValorPagamento == 0) {
      setError('ValorPagamento', { type: 'manual', message: 'Valor de Pagamento inválido' });
      return false;
    } else if (data.PagamentoSemValor) {
      setValue('ValorPagamento', 0);
    }

    // Verifica se a Data de Pagamento não é maior que a data de hoje
    if (data.DataPagamento > hoje) {
      setError('DataPagamento', { type: 'manual', message: 'Data inválida!' });
      return false;
    }

    // Define FormasPagamentoId se não estiver presente
    if (!data.FormasPagamentoId) {
      data.FormasPagamentoId = formasPag?.find((f: any) => f.Tipo === '1')?.FormasPagamentoId || null;
    }

    // Define FonteId se não estiver presente
    if (!data.FonteId) {
      data.FonteId = fontes?.find((f: any) => f.Tipo == 1)?.FonteId || null;
    }

    // Define DataBomPara se não estiver presente
    if (!data.DataBomPara && data.DataPagamento) {
      data.DataBomPara = data.DataPagamento;
    }

    // Define DataDisponivel se não estiver presente
    if (!data.DataDisponivel && data.DataPagamento) {
      data.DataDisponivel = data.DataPagamento;
    }

    // Remove campos desnecessários antes de salvar
    let {
      DataInicialVencimento,
      DataFinalVencimento,
      DataInicialPagamento,
      DataFinalPagamento,
      NomeFiltro,
      FiltroOptions,
      ValorPagar,
      Turma,
      CodigoPagamento,
      DataVencimentoString,
      DataPagamentoString,
      CodigosEstornoId,
      ...dadosOutrosRecebimento
    } = data;

    // Chama a função para salvar Outros Recebimentos
    await salvaOutrosRecebimentos(dadosOutrosRecebimento, data);
  };


  useEffect(() => {
    loadConta()
    loadFormasPagamento()
    loadFontes()
  }, []);

  const cancelar = (e: any) => {
    setDeleteOutrosRecebimentos(false);
    setOutrosRecebimentosDialog(false);
    reset();
  }

  useEffect(() => {
    const fetchTurmaInfo = async () => {
      const { OutrosRecebimentosId, Matricula } = outroRecebimento || {};
      const { EmpresaId, Ano, Sequencial } = periodoSelecionado || {};

      if (OutrosRecebimentosId !== undefined) {
        try {
          const { data } = await api.get('/aluno/pesquisaAlunos', {
            params: { EmpresaId, Ano, Sequencial, Matricula },
          });

          if (data?.length) {
            const turmaInfo = `${data[0].TurmaCodigo} - ${data[0].TurmaDescricao}`;
            setValue('Turma', turmaInfo);
            setTurmaId(data[0].TurmaId || null);
          }
        } catch (error) {
          console.error('Erro ao buscar informações da Turma:', error);
        }
      }
    };

    fetchTurmaInfo();
  }, []);

  useEffect(() => {
    if (watchValor) {
      setValue('ValorDesconto', watchDesconto || 0);
      setValue('ValorPagar', watchValor - watchDesconto);
    }

    if (watchValor < watchDesconto)
      setError('ValorPagar', { type: 'manual', message: 'Valor a pagar negativo.' });
    else
      clearErrors();
  }, [watchValor, watchDesconto]);

  useEffect(() => {
    if (outroRecebimento?.OutrosRecebimentosId !== undefined) {
      let matriculaNome = `${outroRecebimento.Matricula} - ${outroRecebimento.Nome}`
      setValue('Nome', matriculaNome)
      onAlunoSelect(matriculaNome);
    }
  }, []);

  useEffect(() => {
    if (watchAluno) {
      loadAlunos(watchAluno);
    } else {
      setValue('Turma', '')
      setValue('CodigoPagamento', '')
    }
  }, [watchAluno]);

  useEffect(() => {
    if (outroRecebimento?.DataPagamento) {
      setDesabilitaCampos(true)
    }
  }, []);

  useEffect(() => {
    setDesabilita(!watchDataPagamento);

    if (!outroRecebimento?.DataPagamento) {
      if (watchDataPagamento) {
        setValue('FormasPagamentoId', formasPag?.find((f: any) => f.Tipo === '1')?.FormasPagamentoId || null);
        setValue('FonteId', fontes?.find((f: any) => f.Tipo == 0)?.FonteId || null);
      } else {
        setValue('FormasPagamentoId', null);
        setValue('FonteId', null);
      }

      if (!isCartao) {
        setValue('FonteId', null);
      }
    }

  }, [watchDataPagamento, formasPag, fontes]);


  useEffect(() => {
    if (!watchPagamentoSemValor) {
      const valorPagamento = (watchValorPagar || 0) + (watchValorMulta || 0) + (watchValorJuros || 0);

      if (watchDataPagamento) {
        setValue('ValorPagamento', valorPagamento);
      } else {
        setValue('ValorPagamento', 0);
        setValue('ValorMulta', 0);
        setValue('ValorJuros', 0);
      }
    } else {
      setValue('ValorPagamento', 0);
      setValue('ValorMulta', 0);
      setValue('ValorJuros', 0);
    }
  }, [watchDataPagamento, watchValorPagar, watchValorMulta, watchValorJuros, watchPagamentoSemValor]);


  useEffect(() => {
    if (!watchFormaPagamento || !formasPag) return;

    const formaPagamentoSelecionada = formasPag.find((fp: any) => fp.FormasPagamentoId === watchFormaPagamento);
    const isCartao = formaPagamentoSelecionada?.Tipo === '3';
    const codigoCartao = formaPagamentoSelecionada?.Codigo;
    const isMoney = formaPagamentoSelecionada?.Tipo === '1'

    setIsCartao(isCartao);
    setDesabilitaNumeroDocumentoPag(!isCartao);

    if (!watchCartao) {
      setValue('TarifaTipo', null);
      setValue('TarifaValor', '');
      setValue('FonteId', watchFormaPagamento && formaPagamentoSelecionada.Codigo === '00' ? formaPagamentoSelecionada.Fonte : null);
    }

    const buscaCartao = async () => {
      if (!codigoCartao) {
        setCartoesFiltrados([]);
        return;
      }

      try {
        const { data } = await api.get('/cartoes/filtroLista', { params: { Codigo: codigoCartao } });

        if (data?.length > 0) {
          const cartoesInfo = data.map((cartao: any) => ({
            CartoesId: cartao.CartoesId,
            Maquina: `${cartao.Maquina} - ${cartao.FonteCodigo} - ${cartao.FormaDescricao} - ${cartao.Tipo === 'C' ? 'Crédito' : cartao.Tipo === 'D' ? 'Débito' : cartao.Tipo === 'P' ? 'Parcelado' : 'Desconhecido'
              } - ${cartao.QtdParcelas} Parcela(s) - ${cartao.QtdDiasRecebimento} dia(s)`
          }));

          setCartoesFiltrados(cartoesInfo);

          const cartaoSelecionado = data.find((cartao: any) => cartao.CartoesId === watchCartao);

          if (cartaoSelecionado) {
            setValue('TarifaTipo', cartaoSelecionado.TarifaTipo || null);
            setValue('TarifaValor', cartaoSelecionado.TarifaValor);
            setValue('FonteId', cartaoSelecionado.FonteId);
            setMaquinaSelecionada(cartaoSelecionado.Maquina || null);
            setCentroDeCusto(cartaoSelecionado.CentroCustoId || null);
            setContaId(cartaoSelecionado.ContaId || null);
            setQtdDiasRecebimento(cartaoSelecionado.QtdDiasRecebimento || null);

            const dataProjetada = watchDataPagamento && watchCartao ? addDays(watchDataPagamento, cartaoSelecionado.QtdDiasRecebimento) : null
            setValue('DataBomPara', dataProjetada)
            setValue('DataDisponivel', dataProjetada)
          }

        } else {
          setCartoesFiltrados([]);
        }
      } catch (error) {
        console.error('Erro ao buscar cartão:', error);
        setCartoesFiltrados([]);
      }
    };

    // Caso a FORMA DE PAGTO for ** DINHEIRO **
    setValue('DataBomPara', isMoney ? watchDataPagamento || null : null);
    setValue('DataDisponivel', isMoney ? watchDataPagamento || null : null);

    if (isCartao) {
      buscaCartao();
    } else {
      setValue('CartoesId', null);
      setCartoesFiltrados([]);
    }

  }, [watchFormaPagamento, formasPag, watchCartao]);

  useEffect(() => {
    if (deleteOutrosRecebimentos)
      loadCodigoEstorno()

  }, []);

  return (
    <>
      <FormProvider {...methods}>
        {loading ? <Loading full={false} /> : (
          <form onSubmit={handleSubmit(onSubmit)} style={{ minHeight: 300, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} >
            <TabView renderActiveOnly={false}>
              <TabPanel header="Principal">
                <div className='grid' style={{ marginTop: -10 }}>
                  <div className="col-12 md:col-2">
                    <SCInputText
                      control={control}
                      errors={errors}
                      name="NumeroRecibo"
                      label='Nº Recibo'
                      maxLength={10}
                      disabled
                      style={{ color: "#000", fontWeight: 'bold' }}
                    />
                  </div>
                </div>

                <div className='grid align-items-center'>
                  <div className="col-12 md:col-6">
                    <span style={{ fontSize: 12 }}>Nome</span>
                    <AutoComplete
                      name="Nome"
                      value={watchAluno}
                      suggestions={alunos}
                      completeMethod={(e: any) => loadAlunos(e.query)}
                      onChange={(e: any) => {
                        const valorNome = e.target.value;
                        setValue('Nome', valorNome); // Define o nome diretamente
                      }}
                      onSelect={(e: any) => onAlunoSelect(e.value)} // Continua chamando onAlunoSelect quando um aluno é selecionado
                      autoFocus
                      disabled={deleteOutrosRecebimentos || desabilitaCampos}
                    />

                  </div>
                </div>

                <div className='grid'>
                  <div className="col-12 md:col-6">
                    <SCInputText
                      control={control}
                      errors={errors}
                      name="Turma"
                      label='Turma'
                      disabled
                      style={{ color: "#000", fontWeight: 'bold' }}
                    />
                  </div>

                  <div className="col-12 md:col-6">
                    <SCInputText
                      control={control}
                      errors={errors}
                      name="CodigoPagamento"
                      label='Código Pagamento'
                      disabled
                      style={{ color: "#000", fontWeight: 'bold' }}
                    />
                  </div>
                </div>

                <div className='grid'>
                  <div className="col-12 md:col-6">
                    <SCDropdown
                      control={control}
                      errors={errors}
                      name="PlanoContaId"
                      label='Conta'
                      options={contas}
                      optionLabel='Descricao'
                      optionValue='PlanoContaId'
                      disabled={deleteOutrosRecebimentos || desabilitaCampos}
                      required
                    />
                  </div>

                  <div className="col-12 md:col-6">
                    <SCInputText
                      control={control}
                      errors={errors}
                      name="Referencia"
                      label='Referente'
                      disabled={desabilitaCampos}
                    />
                  </div>
                </div>

                <SCFieldset legend='Vencimento' className='col-12 mt-1'>
                  <div className='grid flex align-items-center' style={{ marginTop: -20 }}>
                    <div className="col-12 md:col-2">
                      <SCCalendar
                        errors={errors}
                        label='Data'
                        control={control}
                        name='DataVencimento'
                        dateFormat='dd/mm/yy'
                        disabled={deleteOutrosRecebimentos || desabilitaCampos}
                        required={!deleteOutrosRecebimentos}
                        showIcon
                      />
                    </div>

                    <div className="col-12 md:col-2">
                      <SCInputNumber
                        control={control}
                        name='Valor'
                        errors={errors}
                        label='Valor'
                        disabled={deleteOutrosRecebimentos || desabilitaCampos}
                        min={0}
                        max={10000}
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        currency='BRL'
                        mode='currency'
                        locale='pt-BR'
                        required={!deleteOutrosRecebimentos}
                      />
                    </div>

                    <div className="col-12 md:col-2">
                      <SCInputNumber
                        control={control}
                        name='ValorDesconto'
                        errors={errors}
                        label='Desconto'
                        disabled={deleteOutrosRecebimentos || desabilitaCampos}
                        min={0}
                        max={10000}
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        currency='BRL'
                        mode='currency'
                        locale='pt-BR'
                      />
                    </div>

                    <div className="col-12 md:col-2">
                      <SCInputNumber
                        control={control}
                        name='ValorPagar'
                        errors={errors}
                        label='Valor a Pagar'
                        disabled
                        min={0}
                        max={10000}
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        currency='BRL'
                        mode='currency'
                        locale='pt-BR'
                      />
                    </div>
                  </div>
                </SCFieldset>

                <SCFieldset legend='Pagamento' className='col-12 mt-2'>
                  <div className='grid align-items-center' style={{ marginTop: -20 }}>
                    <div className="col-12 md:col-2">
                      <SCCalendar
                        label='Data'
                        errors={errors}
                        control={control}
                        name='DataPagamento'
                        dateFormat='dd/mm/yy'
                        disabled={deleteOutrosRecebimentos || desabilitaCampos}
                        showIcon
                      />
                    </div>

                    <div className="col-12 md:col-5 mt-2 mb-2 md:mt-4">
                      <SCCheckbox
                        control={control}
                        name="PagamentoSemValor"
                        label='Pagamento sem valor recebido'
                        disabled={deleteOutrosRecebimentos || desabilitaCampos || desabilita}
                      />
                    </div>
                  </div>

                  <div className='grid flex align-items-center' style={{ marginTop: -10 }}>
                    <div className="col-12 md:col-2">
                      <SCInputNumber
                        name='ValorMulta'
                        control={control}
                        errors={errors}
                        label='Multa'
                        disabled={deleteOutrosRecebimentos || desabilitaCampos || desabilita}
                        min={0}
                        max={10000}
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        currency='BRL'
                        mode='currency'
                        locale='pt-BR'
                        required={validaCampos}
                      />
                    </div>

                    <div className="col-12 md:col-2">
                      <SCInputNumber
                        name='ValorJuros'
                        control={control}
                        errors={errors}
                        label='Juros'
                        disabled={deleteOutrosRecebimentos || desabilitaCampos || desabilita}
                        min={0}
                        max={10000}
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        currency='BRL'
                        mode='currency'
                        locale='pt-BR'
                        required={validaCampos}
                      />
                    </div>

                    <div className="col-12 md:col-2">
                      <SCInputNumber
                        name='ValorPagamento'
                        control={control}
                        errors={errors}
                        label='Valor Pago'
                        disabled={deleteOutrosRecebimentos || desabilitaCampos || desabilita}
                        min={0}
                        max={10000}
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        currency='BRL'
                        mode='currency'
                        locale='pt-BR'
                        required={!!watchDataPagamento && watchValorPagamento > 0}
                      />
                    </div>

                    <div className="col-12 md:col-6">
                      <SCDropdown
                        control={control}
                        errors={errors}
                        name="FormasPagamentoId"
                        label='Forma de Pagamento'
                        options={formasPag}
                        optionLabel='Descricao'
                        optionValue='FormasPagamentoId'
                        disabled={deleteOutrosRecebimentos || desabilitaCampos || desabilita}
                        required={validaCampos}
                      />
                    </div>
                  </div>

                  <div className='grid flex align-items-center'>

                    <div className="col-12 md:col-5">
                      <SCDropdown
                        control={control}
                        errors={errors}
                        name="CartoesId"
                        label='Cartão'
                        options={cartoesFiltrados}
                        optionLabel='Maquina'
                        optionValue='CartoesId'
                        disabled={deleteOutrosRecebimentos || desabilitaCampos || desabilita || !isCartao}
                        style={{ width: "100%" }}
                      />
                    </div>

                    <div className="col-12 md:col-1">
                      <SCInputText
                        control={control}
                        errors={errors}
                        name="TarifaValor"
                        label={watchTarifaTipo == 1 ? 'Tarifa(%)' : watchTarifaTipo == 2 ? 'Tarifa(R$)' : 'Tarifa'}
                        disabled
                        style={{ color: "#000", fontWeight: 'bold', width: "100%" }}
                      />
                    </div>
                    <div className="col-12 md:col-2">
                      <SCInputText
                        control={control}
                        errors={errors}
                        name="NumeroDocumento"
                        label='Número do Documento'
                        disabled={deleteOutrosRecebimentos || desabilitaCampos || !watchCartao || !isCartao}
                        required={watchCartao ? true : false}
                      />
                    </div>

                    <div className="col-12 md:col-4">
                      <SCDropdown
                        control={control}
                        errors={errors}
                        name="FonteId"
                        label='Fonte'
                        options={fontes}
                        optionLabel='Codigo'
                        optionValue='FonteId'
                        disabled={deleteOutrosRecebimentos || desabilitaCampos || desabilita}
                        required={validaCampos}
                      />
                    </div>
                  </div>
                  <div className='grid flex align-items-center'>
                    <div className="col-12 md:col-2">
                      <SCCalendar
                        errors={errors}
                        label='Bom Para'
                        control={control}
                        name='DataBomPara'
                        dateFormat='dd/mm/yy'
                        disabled={deleteOutrosRecebimentos || desabilitaCampos || desabilita}
                        showIcon
                        required={validaCampos}
                      />
                    </div>

                    <div className="col-12 md:col-2">
                      <SCCalendar
                        errors={errors}
                        label='Disponível Em'
                        control={control}
                        name='DataDisponivel'
                        dateFormat='dd/mm/yy'
                        disabled={deleteOutrosRecebimentos || desabilitaCampos || desabilita}
                        showIcon
                        required={validaCampos}
                      />
                    </div>
                  </div>

                </SCFieldset>
              </TabPanel>

              <TabPanel header="Observações">
                <SCFieldset legend='Observação' className='col-12 mt-3'>
                  <div className='grid'>
                    <div className="col-12">
                      <SCInputTextarea
                        style={{ minHeight: 300 }}
                        control={control}
                        errors={errors}
                        name="Observacao"
                        //label='Observação'
                        disabled={deleteOutrosRecebimentos || desabilitaCampos}
                        rows={10}
                      />
                    </div>
                  </div>
                </SCFieldset>
              </TabPanel>
            </TabView>

            {deleteOutrosRecebimentos ? (
              <div className="flex flex-1 justify-content-between pt-3 px-2">
                <Button label="Cancelar Exclusão" icon="pi pi-times" className="p-button w-auto p-button-danger" onClick={cancelar} type='button' />

                <div className="col-6">
                  <div className='grid align-items-center justify-content-around'>
                    <span style={{ color: 'red', fontWeight: 'bold' }}>Informe o Código do Estorno</span>
                    <div className='col-12 md:col-7 p-0'>
                      <SCDropdown
                        control={control}
                        errors={errors}
                        name="CodigosEstornoId"
                        options={codigoEstorno}
                        optionLabel='Descricao'
                        optionValue='CodigosEstornoId'
                        required
                        showClear={false}
                      />
                    </div>
                  </div>
                </div>
                <Button label="Confirmar Exclusão" icon="pi pi-check"
                  className={`p-button w-auto p-button-success p-button-outlined ${util.isDesktop() ? '' : ' ml-2'}`}
                  onClick={deletando} type='button' />
              </div>
            ) : (
              <div className="flex flex-1 justify-content-between pt-3 px-2">
                <SCButton label="Cancelar" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} />

                {!desabilitaCampos && (
                  <SCButton label="Gravar" icon="pi pi-check" className="p-button w-auto p-button-success" type='submit' />
                )}

              </div>
            )}

          </form>
        )}

      </FormProvider >
    </>
  );
};

export default OutrosRecebimentosForm
