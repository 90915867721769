import { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { Divider } from 'primereact/divider';
import { FormProvider, useForm } from 'react-hook-form';
import { SCButton, SCDialog, SCInputText } from '../components';
import { ALERTAS } from '../utilities/constantes';
import SelectAlunoAutoComplete from '../components/SelectAlunoAutoComplete';
import { IAlunoForm } from '../interfaces/IAlunos';
import { IAlunosPeriodo } from '../interfaces/IAlunosPeriodo';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import util from '../utilities/util';
import { Badge } from 'primereact/badge';
import RecebimentoMensalidade from './RecebimentoMensalidade';
import { format } from 'date-fns';


const FichaFinanceira = () => {
  const toast = useToast();
  const { periodoSelecionado } = useAuth();

  let defaultValues: any = {
    Aluno: undefined,
    AlunoPeriodo: undefined,
    Parcelas: [],
    Mensalidade: undefined
  };

  const [loading, setLoading] = useState<boolean>(false);
  const [parcelas, setParcelas] = useState<any[]>([]);
  const [pagamentos, setPagamentos] = useState([]);
  const [expandedRows, setExpandedRows] = useState<any>(null);
  const [showDialog, setShowDialog] = useState<boolean>(false);	
  const [showDelete, setShowDelete] = useState<boolean>(false);

  const dt = useRef<any>(null);

  const methods = useForm({ defaultValues });
  const { handleSubmit, control, formState: { errors },  setValue, setError, clearErrors, watch, getValues, trigger, reset } = methods;

  const watchAluno: IAlunoForm = watch('Aluno');
  const watchAlunoPeriodo:IAlunosPeriodo = watch('AlunoPeriodo');

  const load = async () => {
    setLoading(true);
    const alunoPeriodo = getValues().AlunoPeriodo;
    try {
      const result = await api.get('/mensalidades', {
        params: {
          EmpresaId: periodoSelecionado?.EmpresaId,
          Ano: periodoSelecionado?.Ano,
          Sequencial: periodoSelecionado?.Sequencial,
          Matricula: alunoPeriodo?.Matricula
        }
      });
      const resulPagamentos = await api.get('/mensalidades/pagamentos', {
        params: { Matricula:  alunoPeriodo?.Matricula  }
      });  
      setPagamentos(resulPagamentos.data);
      setParcelas(result.data);

    } catch (e: any) {
      console.error(e.response?.data);
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (watchAluno?.Matricula) {
      getAlunoPeriodo(watchAluno.Matricula);
    }
  }, [watchAluno]);
  
  const getAlunoPeriodo = async (matricula: any) => {
    setLoading(true);
    const { data } = await api.get('/alunosPeriodo/matricula', {
        params: { 
            EmpresaId: periodoSelecionado?.EmpresaId,
            Ano: periodoSelecionado?.Ano,
            Sequencial: periodoSelecionado?.Sequencial,                
            Matricula: matricula 
        }
    });  
    setValue('AlunoPeriodo', data);
    setLoading(false);
  }

  useEffect(() => {
    if (watchAlunoPeriodo?.Matricula) {
      load();
    }
  }, [watchAlunoPeriodo]);

  const onSubmit = async (data: any) => {
    const alunoPeriodo = getValues().AlunoPeriodo;
    const aluno = getValues().Aluno;
    setLoading(true);
    try {

      setLoading(false);
      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
    } catch (error) {
      console.error('Erro ao enviar dados para o backend:', error);
      toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Erro ao gravar os dados.' });
      setLoading(false);
    }
  };

  const editar = (item: any) => {
    if (item.DataVencimento)
    {
      item.DataVencimento = new Date(item.DataVencimento);
      item.DataVencimentoFormatado = format(new Date(item.DataVencimento), 'dd/MM/yyyy');
    }
    
    item.DataPagamento = new Date();
    setValue('Mensalidade', item);
    setShowDialog(true);

  };

  const rowClass = (data: any) => {
    return {
        'bg-teal-300': data?.Parcela == watchAlunoPeriodo?.ParcelaMatricula
    };
  };

  const linhaDetalheTemplate = (data: any) => {    
    return (
      <DataTable 
        value={pagamentos.filter((x: any) => x.MensalidadeId == data.MensalidadeId)} 
        lazy 
        emptyMessage="Nenhum registro encontrado.">
        <Column field="PagamentoData" header="Data Pgto" headerStyle={{ width: 80 }} body={(rowData: any) => {
            return (rowData.PagamentoData ? new Date(rowData.PagamentoData).toLocaleDateString() : '');
        }}></Column>
        <Column field="PagamentoHora" header="Hora Pgto" ></Column>
        <Column field="PagamentoValor" header="Valor Pago" body={(rowData: any) => {
            return (util.formatCurrency(rowData.PagamentoValor));
        }}></Column>
        <Column field="PagamentoJuros" header="Juros" body={(rowData: any) => {
            return (util.formatCurrency(rowData.PagamentoJuros));
        }}></Column>
        <Column field="PagamentoMulta" header="Multa" body={(rowData: any) => {
            return (util.formatCurrency(rowData.PagamentoMulta));
        }}></Column>
        <Column field="FormasPagamentoDescricao" header="Forma de Pagamento" ></Column>
        <Column field="FonteDescricao" header="Fonte" ></Column>
      </DataTable>
    );
  };

  const hideDialog = () => {
    setShowDialog(false);
  };

  const cancelar = (e: any) => {
    setValue('Mensalidade', undefined);
    setShowDelete(false);
    setShowDialog(false);
  }

  const onSubmitCad = async () => {
    setLoading(true);
    const data = getValues();
    
    console.log('data', data);

    const formValido = await trigger();
    if (!formValido) {
      setLoading(false);
      return;
    }

    try {

      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
      setShowDialog(false);
    } catch (e: any) {
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }
    setLoading(false);
  }

  return (
    <FormProvider {...methods}>
    <form onSubmit={handleSubmit(onSubmit)} className='pt-3' >
      <div className="grid crud-demo">
        <div className="col-12">
          <div className="card">
            <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
              <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Ficha Financeira - [{`${periodoSelecionado?.Ano} / ${periodoSelecionado?.Sequencial}`}]</h5>
            </div>

            <div className='grid mt-2'>
              <div className='col-12'>
                <div className='grid'>
                  <div className='col-12 md:col-6'>
                    <SelectAlunoAutoComplete 
                      name='AlunoId'
                      control={control}
                      errors={errors}
                      label='Aluno'
                      loading={loading}
                      minLength={3}
                      setValue={setValue}
                      setError={setError}
                      clearErrors={clearErrors}
                    />
                  </div>
                  
                  <div className='col-12 md:col-2'>
                    <SCInputText
                      control={control}
                      errors={errors}
                      name="Aluno.TurmaCodigo"
                      label="Código da Turma"
                      disabled
                      className='w-full'
                    />
                  </div>
                  <div className='col-12 md:col-4'>
                    <SCInputText
                      control={control}
                      errors={errors}
                      name="Aluno.TurmaDescricao"
                      label="Nome da Turma"
                      disabled
                      className='w-full'
                    />
                  </div>
                </div>                
              </div>              
            </div>

            <Divider />

            <DataTable
              size='small'
              stripedRows
              ref={dt}
              value={parcelas}
              dataKey="MensalidadeId"
              emptyMessage="Nenhum registro encontrado."
              header={null}
              responsiveLayout="scroll"
              rowExpansionTemplate={linhaDetalheTemplate}
              expandedRows={expandedRows}    
              // rowClassName={rowClass}
              onRowToggle={(e) => setExpandedRows(e.data)}         
              >
              <Column expander style={{ width: '5rem' }} />
              <Column field="Parcela" header="" headerStyle={{ width: 20 }} body={(rowData: any) => {
                if (rowData.Parcela == watchAlunoPeriodo.ParcelaMatricula)
                  return (<Badge size="large" value="M" />);
              }}></Column>              
              <Column field="Parcela" header="Parcela" bodyStyle={{textAlign: 'center'}} headerStyle={{ width: 40 }}></Column>
              <Column field="ParcelaDescricao" header="Descrição" sortable ></Column>
              <Column field="ValorMensalidade" header="Valor Total" headerStyle={{ width: 120 }} body={(rowData: any) => {
                  return rowData.ValorMensalidade.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
              }}></Column>
              
              <Column field="DataVencimento" header="Vencimento" sortable headerStyle={{ width: 70 }} body={(rowData: any) => {
                  return rowData.DataVencimento ? new Date(rowData.DataVencimento).toLocaleDateString() : '';
              }}></Column>

              <Column field="ValorPago" header="Valor Pago" headerStyle={{ width: 120 }} body={(rowData: any) => {
                  return util.formatCurrency(rowData.ValorPago);
              }}></Column>

              <Column field="DataUltimoPagamento" header="Data Ultimo Pgto" sortable headerStyle={{ width: 160 }} body={(rowData: any) => {
                  return rowData.DataUltimoPagamento ? `${new Date(rowData.DataUltimoPagamento).toLocaleDateString()} ${rowData.HoraUltimoPagamento.substr(0,5)}` : '';
              }}></Column>

              <Column body={(rowData: any) => {
                return (
                  <div className="flex align-items-center justify-content-between">
                    <Button type='button' icon="pi pi-money-bill" className="p-button-rounded p-button-info" onClick={() => editar(rowData)} 
                    disabled={rowData.SituacaoAcademica === 'R'} />
                    {/* <Button icon="pi pi-arrow-up-right" className="p-button-rounded p-button-info ml-2" onClick={() => editar(rowData)} disabled={rowData.SituacaoAcademica === 'R'} /> */}
                  </div>
                );
              }} style={{ width: 50 }}
              />
            </DataTable>

          </div>
        </div>
      </div>
      <SCDialog maximized={!util.isDesktop()} visible={showDialog} style={{ width: util.isDesktop() ? '80%' : '100%', height: '94vh' }} closable={!util.isDesktop()}
          header={`Recebimento de Mensalidade`}
          modal className="p-fluid" onHide={hideDialog}
          footer={
            <div className="flex flex-1 justify-content-between">
              <SCButton label="Cancelar" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} />
              <SCButton onClick={onSubmitCad} label="Gravar" icon="pi pi-check" className="p-button w-auto p-button-success" />
            </div>
          }>
        <RecebimentoMensalidade
          setShowDialog={setShowDialog}
          aluno={watchAluno}
          alunoPeriodo={watchAlunoPeriodo}
          mensaliade={getValues().Mensalidade}
          pagamentos={pagamentos.filter((x: any) => x.MensalidadeId == getValues()?.Mensalidade?.MensalidadeId)}
          reload={() => load()}
          showDelete={showDelete}
          setShowDelete={setShowDelete}
          loading={loading}
          deletar={() => {}}
          setLoading={setLoading}
        />

      </SCDialog>
    </form>
    </FormProvider>


  );
};

export default FichaFinanceira;
